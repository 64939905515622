declare type AddressMap = {
  [chainId: number]: string
}

export const LOCKER_V2_CONTRACTS: AddressMap = {
  [52014]: '0x16ca736c8B181772009e598F37f137e9cD36AFAE',
  [5201420]: '0xea09eCd0060987651CF54863329fBA82561559D6',
}

export const LOCKER_V3_CONTRACTS: AddressMap = {
  [52014]: '0xfdB0d62Fc929fD53D266B969Bfe4250b205D0899',
  [5201420]: '0x150626A1E2B5831B5Bf3Db73133679987152acC5',
}

export const FOT_DETECTOR_CONTRACTS: AddressMap = {
  [52014]: '0x34dc8af1FFe9F71aB8B37F9Ea79c567ab64140b3',
  [5201420]: '0x6B60A34Bb42d40ed22f3239447340635DB19C780',
}

export const CANNOT_MIGRATE = [
  '0xa5Fb801c30FDC9b0532583BF02Df15E36e7b1a16',
  '0x24819a96Aca74897E7E6c6a8AF27CA5e5DAd701d',
  '0x0d138f0bf5C7Bb25A078F791E5802776656e82D3',
  '0x4D2b867FCa568B5DC6367646811FaA4ED3C0520F',
]

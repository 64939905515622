import { ApolloError } from '@apollo/client'
import { Chain, LiquidityLock, useLiquidityLocksQuery } from 'graphql/data/__generated__/types-and-hooks'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'

gql`
  query LiquidityLocks($chain: Chain!, $addresses: [String!]!) {
    liquidityLocks(chain: $chain, addresses: $addresses) {
      lockId
      pair
      owner
      created
      duration
      token0
      token1
      percentSupply
      amountToken1
      amountToken0
      active
      version
    }
  }
`

interface LiquidityLocksReturnValue {
  liquidityLocks?: LiquidityLock[]
  loading: boolean
  error?: ApolloError
}

export function useLiquidityLocks(chain: Chain, addresses: string[]): LiquidityLocksReturnValue {
  const { data, loading, error } = usePollQueryWhileMounted(
    useLiquidityLocksQuery({
      variables: { chain, addresses },
      skip: addresses.length === 0,
    }),
    PollingInterval.Slow
  )

  return useMemo(
    () => ({
      liquidityLocks: data?.liquidityLocks as LiquidityLock[],
      loading,
      error,
    }),
    [data, error, loading]
  )
}
